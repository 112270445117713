import React, { useState, useEffect } from 'react';
import Account_Sidebar from '../components/Account_Sidebar';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useProductContext } from '../context/productcontext';
import { useParams } from 'react-router-dom';

const Order_detail_supplier = () => {
    // Redirect if user is not logged in or not a supplier
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    if (JSON.parse(secureLocalStorage.getItem('login_data')).role !== 2) {
        window.location.replace('/login');
    }

    const { id } = useParams();
    const { products } = useProductContext();
    const [data, setData] = useState(null);
    const [order_summary, setSummary] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const GetInvoice = (id) => {
        setLoading(true);
        axios.get(`https://beta.e-square.co.uk:3002/get-orders-single/${id}`, {
            headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
        })
            .then((resp) => {
                if (resp.data.status != 201) {
                    let order = resp.data;
                    axios.get(`https://beta.e-square.co.uk:3002/get-orders-items-supplier/${id}/${JSON.parse(secureLocalStorage.getItem('login_data')).id}`, {
                        headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
                    })
                        .then((res) => {
                            setData({ order: order[0], order_items: res.data });
                            let subtotal = res.data.reduce((total, item) => total + (item.price * item.quantity), 0);
                            let total = (subtotal - (order[0].coupon * subtotal / 100)) + order[0].shipping;
                            setSummary({ subtotal: subtotal, total: total, shipping: order[0].shipping });
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.error('Error fetching order items:', err);
                            setLoading(false);
                        });
                } else {
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.error('Error fetching invoice:', error);
                setLoading(false);
            });
    };

    const updateOrderStatus = () => {
        if (!status) {
            alert('Please select an order status');
            return;
        }
        setLoading(true);
        axios.put(`https://beta.e-square.co.uk:3002/update-order-status/${id}`, {
            order_status: status
        }, {
            headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
        })
            .then((response) => {
                console.log('Status Update Response:', response.data);
                GetInvoice(id);
            })
            .catch((error) => {
                console.error('Error updating order status:', error);
                alert('Failed to update order status.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        GetInvoice(id);
    }, [id]);

    return (
        <section className="user-area">
            <div className="container-fluid">
                <div className="row justify-content-end">
                    <div className="col-md-2 p-0"><Account_Sidebar /></div>
                    <div className="col-md-10 p-0">
                        {!data ? <div className='notfound'>No order data found.</div> :
                            <div className='invoice-popup open'>
                                <div className="card">
                                    <div className="card-body mx-4">
                                        <p className="my-3 mb-4" style={{ fontSize: 30 }}>
                                            {data.order.order_number}
                                            <span>Status: <b>{data.order.payment_status === 0 ? "Not Paid" : data.order.order_status}</b></span>
                                        </p>
                                        <div className="update-order-status">
                                            <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                                <option value="" hidden>{data.order.order_status}</option>
                                                <option value="pending">Pending</option>
                                                <option value="shipped">Shipped</option>
                                                <option value="completed">Completed</option>
                                                <option value="cancelled">Cancelled</option>
                                            </select>
                                            <button onClick={updateOrderStatus} className={`btn-update ${loading && "loadin"}`}>Update</button>
                                        </div>
                                        <div className="row">
                                            <ul className="list-unstyled col-md-4">
                                                <li className="text-black">Buyer Name: <b> {data.order.billing_first_name + ' ' + data.order.billing_last_name}</b></li>
                                                <li className="text-black">Contact: <b> {data.order.billing_contact}</b></li>
                                                <li className="text-black">Email: <b> {data.order.billing_email}</b></li>
                                                <li className="text-black">Country: <b> {data.order.billing_country}</b></li>
                                            </ul>
                                            <ul className="list-unstyled col-md-4">
                                                <li className="text-black">Address: <b> {data.order.billing_address1}</b></li>
                                                <li className="text-black">State: <b> {data.order.billing_state}</b></li>
                                                <li className="text-black">City: <b> {data.order.billing_city}</b></li>
                                                <li className="text-black">Zip: <b> {data.order.billing_zipcode}</b></li>
                                            </ul>
                                            <ul className="list-unstyled col-md-4">
                                                <li className="text-black">Subtotal: <b> £{numberWithCommas(order_summary.subtotal)}</b></li>
                                                <li className="text-black">Discount: <b> {data.order.coupon}%</b></li>
                                                <li className="text-black">Discount: <b> £{numberWithCommas(order_summary.shipping)}</b></li>
                                                <li className="text-black" style={{ fontSize: '18px' }}>Total: <b> £{numberWithCommas(order_summary.total)}</b></li>
                                            </ul>
                                        </div>
                                        <div className="table-responsive">
                                            {data.order_items.length !== 0 ?
                                                <table className="table border">
                                                    <thead>
                                                        <tr>
                                                            <th>Item</th>
                                                            <th>Quantity</th>
                                                            <th>Unit Price</th>
                                                            <th>Sub Price</th>
                                                            <th>Tracking Code</th>
                                                            <th>Label</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.order_items.map((cart) => (
                                                            products.filter((elem) => elem.id === cart.item_id).map((elem) => {
                                                                const shipment_data = JSON.parse(cart.shipment_data);
                                                                return (
                                                                    <tr className="space" key={cart.id}>
                                                                        <td className="col-md-4">
                                                                            <div className="row">
                                                                                <div className="table-space">
                                                                                    <div className="row">
                                                                                        <div className="col-lg-3 no-padding">
                                                                                            <div className="product-img">
                                                                                                <img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/products/ath_${elem.user_id}/${elem.thumbnail}`} className="img-fluid" alt="img" />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-9 no-space">
                                                                                            <h3>{elem.title}</h3>
                                                                                            <div className='variants-crt m-0'>
                                                                                                {cart.variations && (() => {
                                                                                                    let variant = JSON.parse(cart.variations);
                                                                                                    return Object.keys(variant).map((ver) => (
                                                                                                        <h4 key={ver}>{ver}: <span>{variant[ver]}</span></h4>
                                                                                                    ));
                                                                                                })()}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td><h4 className='m-0'>{cart.quantity}</h4></td>
                                                                        <td><h4 className='m-0'>£{numberWithCommas(cart.price)}</h4></td>
                                                                        <td><h4 className='m-0'>£{numberWithCommas(cart.price * cart.quantity)}</h4></td>
                                                                        <td>
                                                                            {shipment_data?.tracking_codes?.length > 0 && shipment_data?.tracking_urls?.length > 0 ? (
                                                                                <>
                                                                                    <h4 className='m-0 small'>{shipment_data.tracking_codes[0]}</h4>
                                                                                    <a href={shipment_data.tracking_urls[0]} target='_blank' rel='noopener noreferrer' className='btn-new'>
                                                                                        Track
                                                                                    </a>
                                                                                </>
                                                                            ) : (
                                                                                <p className='text-muted small'>No tracking info available</p>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {shipment_data?.saved_file_path ? (
                                                                                <a href={`https://beta.e-square.co.uk/admin/backend/label_storage/${shipment_data.saved_file_path}`}
                                                                                    target='_blank' rel='noopener noreferrer' className='btn-new'>
                                                                                    View Label
                                                                                </a>
                                                                            ) : (
                                                                                <p className='text-muted small'>No label available</p>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ))}
                                                    </tbody>
                                                </table>
                                                : <div className='notfound cart-not text-center'>
                                                    <img src='images/cart-empty.png' alt="Empty Cart" />
                                                    <h2>No items in cart</h2>
                                                </div>}
                                        </div>
                                    </div>
                                </div>

                            </div>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Order_detail_supplier;

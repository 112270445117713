import React, { useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useEffect } from 'react';
import Account_Sidebar from '../components/Account_Sidebar';
import { omit } from 'lodash'
import Reset_Password from './Reset_Password';
import  secureLocalStorage  from  "react-secure-storage";

const Profile = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }

    const id = JSON.parse(secureLocalStorage.getItem('login_data')).id;
    const [user, setUser] = useState({});

    const [previe, SetPreview] = useState(null);
    const [profile_pic, setImage] = useState([]);
    const [errors, setErrors] = useState({});


    let err = {};
    const validate2 = (name, value) => {
        if (name === 'first_name')
            if (value === null || value == undefined || value == '') {
                err = { ...err, first_name: 'Field cannot be empty' }
            } else if (value.length <= 4) {
                err = { ...err, first_name: 'First name atleast have 5 letters' }
            } else {
                let newObj = omit(errors, "first_name");
                setErrors(newObj);
            }
        if (name === 'last_name')
            if (value === null || value == undefined || value.length == 0) {
                err = { ...err, last_name: 'Field cannot be empty' }
            } else if (value.length <= 4) {
                err = { ...err, last_name: 'Last name atleast have 5 letters' }
            } else {
                let newObj = omit(errors, "last_name");
                setErrors(newObj);
            }
        if (name === 'billing_address_1')
            if (value === null || value == undefined || value == '') {
                err = { ...err, billing_address_1: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "billing_address_1");
                setErrors(newObj);
            }
        if (name === 'billing_city')
            if (value === null || value == undefined || value == '') {
                err = { ...err, billing_city: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "billing_city");
                setErrors(newObj);
            }
        if (name === 'billing_state')
            if (value === null || value == undefined || value == '') {
                err = { ...err, billing_state: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "billing_state");
                setErrors(newObj);
            }
        if (name === 'billing_zip_code')
            if (value === null || value == undefined || value == '') {
                err = { ...err, billing_zip_code: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "billing_zip_code");
                setErrors(newObj);
            }
        if (name === 'bio')
            if (value === null || value == undefined || value == '') {
                err = { ...err, bio: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "bio");
                setErrors(newObj);
            }
        if (name === 'country')
            if (value === null || value == undefined || value == '') {
                err = { ...err, country: 'Field cannot be empty' }
            } else {
                let newObj = omit(errors, "country");
                setErrors(newObj);
            }
        if (name === 'profile_pic')
            if (value === null || value == undefined || value == '') {
                err = { ...err, profile_pic: 'Profile Pic is Required' }
            } else {
                let newObj = omit(errors, "profile_pic");
                setErrors(newObj);
            }
        if (name === 'phone') {
            if (
                !new RegExp(/(\+\d{1,3}\s?)?(\()?\d{3}(\))?[-\s]?\d{3}[-\s]?\d{4}/).test(value)
            ) {
                err = { ...err, phone: 'Enter a valid Phone Number' }
            } else {
                let newObj = omit(errors, "phone");
                setErrors(newObj);
            }
        }
        setErrors(err)
    }



    const validate = (name, value) => {
        switch (name) {
            case 'first_name':
                if (value === null || value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        first_name: 'Field cannot be empty'
                    })
                } else if (value.length <= 4) {
                    setErrors({
                        ...errors,
                        first_name: 'First name atleast have 5 letters'
                    })
                } else {
                    let newObj = omit(errors, "first_name");
                    setErrors(newObj);
                }
                break;
            case 'last_name':
                if (value === null || value == undefined || value.length == 0) {
                    setErrors({
                        ...errors,
                        last_name: 'Field cannot be empty'
                    })
                } else if (value.length <= 4) {
                    setErrors({
                        ...errors,
                        last_name: 'Last name atleast have 5 letters'
                    })
                } else {
                    let newObj = omit(errors, "last_name");
                    setErrors(newObj);
                }
                break;
            case 'billing_address_1':
                if (value === null || value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        billing_address_1: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "billing_address_1");
                    setErrors(newObj);
                }
                break;
            case 'billing_city':
                if (value === null || value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        billing_city: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "billing_city");
                    setErrors(newObj);
                }
                break;
            case 'bio':
                if (value === null || value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        bio: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "bio");
                    setErrors(newObj);
                }
                break;
            case 'country':
                if (value === null || value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        country: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "country");
                    setErrors(newObj);
                }
                break;
            case 'profile_pic':
                if (value === null || value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        profile_pic: 'Profile Pic is Required'
                    })
                } else {
                    let newObj = omit(errors, "profile_pic");
                    setErrors(newObj);
                }
                break;
            case 'intrest':
                if (value === null || value.length == 0 || value == '') {
                    setErrors({
                        ...errors,
                        intrest: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "intrest");
                    setErrors(newObj);
                }
                break;
            case 'billing_state':
                if (value === null || value.length == 0 || value == '') {
                    setErrors({
                        ...errors,
                        billing_state: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "billing_state");
                    setErrors(newObj);
                }
                break;
            case 'billing_zip_code':
                if (value === null || value.length == 0 || value == '') {
                    setErrors({
                        ...errors,
                        billing_zip_code: 'Field cannot be empty'
                    })
                } else {
                    let newObj = omit(errors, "billing_zip_code");
                    setErrors(newObj);
                }
                break;
            case 'phone':
                if (
                    !new RegExp(/(\+\d{1,3}\s?)?(\()?\d{3}(\))?[-\s]?\d{3}[-\s]?\d{4}/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        phone: 'Enter a valid Phone Number'
                    })
                } else {
                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }

    const getUser = async () => {
        await axios.get(`https://beta.e-square.co.uk:3002/user/${id}`, {headers : {'Auth' : secureLocalStorage.getItem('accessToken')}}).then(function (response) {
            setUser(response.data.data[0]);
            secureLocalStorage.setItem('login_data', JSON.stringify(response.data.data[0]));
            Object.keys(response.data.data[0]).map((ket) => {
                validate2(ket, response.data.data[0][ket]);
            })
            $('body').removeClass('Loading');
        })
    }
    const setVal = (e) => {
        setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        validate(e.target.name, e.target.value);
    }
    const handleFile = (e) => {
        setImage(e.target.files[0])
        validate(e.target.name, e.target.files[0].file);
        previewFile();
    }
    useEffect(() => {
        getUser()
    }, [])

    const UpdateUser = async (e) => {
        e.preventDefault();
        e.target.classList.add('loadin');
        await axios.put(`https://beta.e-square.co.uk:3002/updateUser/${id}/${user.role}`, { ...user, file: profile_pic }, { headers: { "Content-Type": "multipart/form-data", 'Auth' : secureLocalStorage.getItem('accessToken') } }).then(function (response) {
            if (response.data.status == '200') {
                $('div:not(.rsst) .msg-box').removeClass('red')
                $('div:not(.rsst) .msg-box').addClass('green').text(response.data.message);
                $('.aut-form-box input').val('');
                setTimeout(function () {
                    setUser({});
                    $('div:not(.rsst) .msg-box').hide()
                    getUser()
                }, 3000)
            } else {
                $('div:not(.rsst) .msg-box').removeClass('green')
                $('div:not(.rsst) .msg-box').addClass('red').text(response.data.message);
            }
        }).catch(function (error) {
            // console.log(error);
        });

    }
    const previewFile = () => {
        const preview = document.querySelector(".upload-btn-wrapper img");
        const label = $(".upload-btn-wrapper label");
        const file = document.querySelector("#profile").files[0];
        const reader = new FileReader();

        reader.addEventListener(
            "load",
            () => {
                SetPreview(reader.result);
                label.text(file.name)
            },
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    }



    if (Object.keys(user).length === 0) {
        return <div className='Loading' > Loading... </div>
    } else {
        return (
            <>
                <section className="user-area">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-md-2 p-0"><Account_Sidebar /></div>
                            <div className="col-md-10 p-0">
                                <div className="body-wrapper">
                                    <div className="userInfo">
                                        <div className='switch-box-head'>
                                            <h3>Your Info</h3>
                                        </div>
                                        <div className="row infg">
                                            {
                                                user.profile_pic != null ?
                                                    <div className="col-lg-12 col-md-6 mb-4">
                                                        <div className="upload-btn-wrapper">
                                                            <div className="left">
                                                                <button className="btn"><img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/users/${user.profile_pic}`} /></button>
                                                                <input type="file" name="profile_pic" id='profile' onChange={handleFile} />
                                                            </div>
                                                            <label className='d-none'>Upload Your Photo</label>
                                                            {/* {errors.profile_pic ? <div className='errorr mb-0'>{errors.profile_pic}</div> : false} */}
                                                        </div>
                                                    </div>
                                                    : <div className="col-lg-12 col-md-6 mb-4">
                                                        <div className="upload-btn-wrapper">
                                                            <div className="left">
                                                                {previe !== null ? <button className="btn"><img src={previe} /></button> : <button className="btn"><span className='dummy-pics'>{user.first_name[0]}</span></button>}
                                                                <input type="file" name="profile_pic" id='profile' onChange={handleFile} />
                                                            </div>
                                                            <label className='d-none'>Upload Your Photo</label>
                                                            {/* {errors.profile_pic ? <div className='errorr mb-0'>{errors.profile_pic}</div> : false} */}
                                                        </div>
                                                    </div>
                                            }
                                            <div className="col-lg-4">
                                                <label>First Name</label>
                                                <input type="text" name='first_name' onChange={setVal} defaultValue={user.first_name} />
                                                {errors.first_name ? <div className='errorr mb-0'>{errors.first_name}</div> : false}
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Last Name</label>
                                                <input type="text" name='last_name' onChange={setVal} defaultValue={user.last_name} />
                                                {errors.last_name ? <div className='errorr mb-0'>{errors.last_name}</div> : false}
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Phone</label>
                                                <input type="text" name='phone' onChange={setVal} defaultValue={user.phone} />
                                                {errors.phone ? <div className='errorr mb-0'>{errors.phone}</div> : false}
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Address</label>
                                                {/* {errors.billing_address_1 ? <div className='errorr mb-0'>{errors.billing_address_1}</div> : false} */}
                                                <input type="text" name='billing_address_1' onChange={setVal} defaultValue={user.billing_address_1} />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>City</label>
                                                {/* {errors.billing_city ? <div className='errorr mb-0'>{errors.billing_city}</div> : false} */}
                                                <input type="text" name='billing_city' onChange={setVal} defaultValue={user.billing_city} />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>State</label>
                                                {/* {errors.billing_billing_state ? <div className='errorr mb-0'>{errors.billing_state}</div> : false} */}
                                                <input type="text" name='billing_state' onChange={setVal} defaultValue={user.billing_state} />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Zip</label>
                                                {/* {errors.billing_zip_code ? <div className='errorr mb-0'>{errors.billing_zip_code}</div> : false} */}
                                                <input type="text" name='billing_zip_code' onChange={setVal} defaultValue={user.billing_zip_code} />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Country</label>
                                                {errors.country ? <div className='errorr mb-0'>{errors.country}</div> : false}
                                                <div className="select-style">
                                                    <select name="country" onChange={setVal}>
                                                        <option value={user.country} selected hidden disabled>{user.country}</option>
                                                        <option value="AF">Afghanistan</option>
                                                        <option value="AX">Aland Islands</option>
                                                        <option value="AL">Albania</option>
                                                        <option value="DZ">Algeria</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AQ">Antarctica</option>
                                                        <option value="AG">Antigua and Barbuda</option>
                                                        <option value="AR">Argentina</option>
                                                        <option value="AM">Armenia</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU">Australia</option>
                                                        <option value="AT">Austria</option>
                                                        <option value="AZ">Azerbaijan</option>
                                                        <option value="BS">Bahamas</option>
                                                        <option value="BH">Bahrain</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BY">Belarus</option>
                                                        <option value="BE">Belgium</option>
                                                        <option value="BZ">Belize</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermuda</option>
                                                        <option value="BT">Bhutan</option>
                                                        <option value="BO">Bolivia</option>
                                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                        <option value="BA">Bosnia and Herzegovina</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BV">Bouvet Island</option>
                                                        <option value="BR">Brazil</option>
                                                        <option value="IO">British Indian Ocean Territory</option>
                                                        <option value="BN">Brunei Darussalam</option>
                                                        <option value="BG">Bulgaria</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="KH">Cambodia</option>
                                                        <option value="CM">Cameroon</option>
                                                        <option value="CA">Canada</option>
                                                        <option value="CV">Cape Verde</option>
                                                        <option value="KY">Cayman Islands</option>
                                                        <option value="CF">Central African Republic</option>
                                                        <option value="TD">Chad</option>
                                                        <option value="CL">Chile</option>
                                                        <option value="CN">China</option>
                                                        <option value="CX">Christmas Island</option>
                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                        <option value="CO">Colombia</option>
                                                        <option value="KM">Comoros</option>
                                                        <option value="CG">Congo</option>
                                                        <option value="CD">Congo, Democratic Republic of the Congo</option>
                                                        <option value="CK">Cook Islands</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="CI">Cote D'Ivoire</option>
                                                        <option value="HR">Croatia</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="CW">Curacao</option>
                                                        <option value="CY">Cyprus</option>
                                                        <option value="CZ">Czech Republic</option>
                                                        <option value="DK">Denmark</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="DO">Dominican Republic</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egypt</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="GQ">Equatorial Guinea</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="EE">Estonia</option>
                                                        <option value="ET">Ethiopia</option>
                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                        <option value="FO">Faroe Islands</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="FI">Finland</option>
                                                        <option value="FR">France</option>
                                                        <option value="GF">French Guiana</option>
                                                        <option value="PF">French Polynesia</option>
                                                        <option value="TF">French Southern Territories</option>
                                                        <option value="GA">Gabon</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgia</option>
                                                        <option value="DE">Germany</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GR">Greece</option>
                                                        <option value="GL">Greenland</option>
                                                        <option value="GD">Grenada</option>
                                                        <option value="GP">Guadeloupe</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GG">Guernsey</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GW">Guinea-Bissau</option>
                                                        <option value="GY">Guyana</option>
                                                        <option value="HT">Haiti</option>
                                                        <option value="HM">Heard Island and Mcdonald Islands</option>
                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HK">Hong Kong</option>
                                                        <option value="HU">Hungary</option>
                                                        <option value="IS">Iceland</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesia</option>
                                                        <option value="IR">Iran, Islamic Republic of</option>
                                                        <option value="IQ">Iraq</option>
                                                        <option value="IE">Ireland</option>
                                                        <option value="IM">Isle of Man</option>
                                                        <option value="IL">Israel</option>
                                                        <option value="IT">Italy</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japan</option>
                                                        <option value="JE">Jersey</option>
                                                        <option value="JO">Jordan</option>
                                                        <option value="KZ">Kazakhstan</option>
                                                        <option value="KE">Kenya</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KP">Korea, Democratic People's Republic of</option>
                                                        <option value="KR">Korea, Republic of</option>
                                                        <option value="XK">Kosovo</option>
                                                        <option value="KW">Kuwait</option>
                                                        <option value="KG">Kyrgyzstan</option>
                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                        <option value="LV">Latvia</option>
                                                        <option value="LB">Lebanon</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libyan Arab Jamahiriya</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Lithuania</option>
                                                        <option value="LU">Luxembourg</option>
                                                        <option value="MO">Macao</option>
                                                        <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                                        <option value="MG">Madagascar</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MY">Malaysia</option>
                                                        <option value="MV">Maldives</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MH">Marshall Islands</option>
                                                        <option value="MQ">Martinique</option>
                                                        <option value="MR">Mauritania</option>
                                                        <option value="MU">Mauritius</option>
                                                        <option value="YT">Mayotte</option>
                                                        <option value="MX">Mexico</option>
                                                        <option value="FM">Micronesia, Federated States of</option>
                                                        <option value="MD">Moldova, Republic of</option>
                                                        <option value="MC">Monaco</option>
                                                        <option value="MN">Mongolia</option>
                                                        <option value="ME">Montenegro</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MA">Morocco</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="MM">Myanmar</option>
                                                        <option value="NA">Namibia</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="NL">Netherlands</option>
                                                        <option value="AN">Netherlands Antilles</option>
                                                        <option value="NC">New Caledonia</option>
                                                        <option value="NZ">New Zealand</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NE">Niger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="NF">Norfolk Island</option>
                                                        <option value="MP">Northern Mariana Islands</option>
                                                        <option value="NO">Norway</option>
                                                        <option value="OM">Oman</option>
                                                        <option value="PK">Pakistan</option>
                                                        <option value="PW">Palau</option>
                                                        <option value="PS">Palestinian Territory, Occupied</option>
                                                        <option value="PA">Panama</option>
                                                        <option value="PG">Papua New Guinea</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Peru</option>
                                                        <option value="PH">Philippines</option>
                                                        <option value="PN">Pitcairn</option>
                                                        <option value="PL">Poland</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="RE">Reunion</option>
                                                        <option value="RO">Romania</option>
                                                        <option value="RU">Russian Federation</option>
                                                        <option value="RW">Rwanda</option>
                                                        <option value="BL">Saint Barthelemy</option>
                                                        <option value="SH">Saint Helena</option>
                                                        <option value="KN">Saint Kitts and Nevis</option>
                                                        <option value="LC">Saint Lucia</option>
                                                        <option value="MF">Saint Martin</option>
                                                        <option value="PM">Saint Pierre and Miquelon</option>
                                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="ST">Sao Tome and Principe</option>
                                                        <option value="SA">Saudi Arabia</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="RS">Serbia</option>
                                                        <option value="CS">Serbia and Montenegro</option>
                                                        <option value="SC">Seychelles</option>
                                                        <option value="SL">Sierra Leone</option>
                                                        <option value="SG">Singapore</option>
                                                        <option value="SX">Sint Maarten</option>
                                                        <option value="SK">Slovakia</option>
                                                        <option value="SI">Slovenia</option>
                                                        <option value="SB">Solomon Islands</option>
                                                        <option value="SO">Somalia</option>
                                                        <option value="ZA">South Africa</option>
                                                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                        <option value="SS">South Sudan</option>
                                                        <option value="ES">Spain</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="SD">Sudan</option>
                                                        <option value="SR">Suriname</option>
                                                        <option value="SJ">Svalbard and Jan Mayen</option>
                                                        <option value="SZ">Swaziland</option>
                                                        <option value="SE">Sweden</option>
                                                        <option value="CH">Switzerland</option>
                                                        <option value="SY">Syrian Arab Republic</option>
                                                        <option value="TW">Taiwan, Province of China</option>
                                                        <option value="TJ">Tajikistan</option>
                                                        <option value="TZ">Tanzania, United Republic of</option>
                                                        <option value="TH">Thailand</option>
                                                        <option value="TL">Timor-Leste</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TK">Tokelau</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad and Tobago</option>
                                                        <option value="TN">Tunisia</option>
                                                        <option value="TR">Turkey</option>
                                                        <option value="TM">Turkmenistan</option>
                                                        <option value="TC">Turks and Caicos Islands</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UA">Ukraine</option>
                                                        <option value="AE">United Arab Emirates</option>
                                                        <option value="GB">United Kingdom</option>
                                                        <option value="US">United States</option>
                                                        <option value="UM">United States Minor Outlying Islands</option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="UZ">Uzbekistan</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela</option>
                                                        <option value="VN">Viet Nam</option>
                                                        <option value="VG">Virgin Islands, British</option>
                                                        <option value="VI">Virgin Islands, U.s.</option>
                                                        <option value="WF">Wallis and Futuna</option>
                                                        <option value="EH">Western Sahara</option>
                                                        <option value="YE">Yemen</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabwe</option>
                                                    </select>
                                                    <i className="fas fa-chevron-down" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <label>About</label>
                                                {/* {errors.bio ? <div className='errorr mb-0'>{errors.bio}</div> : false} */}
                                                <textarea name='bio' onChange={setVal} defaultValue={user.bio}></textarea>
                                            </div>
                                            <div className="col-lg-5 mb-1">
                                                <button className="login_btn" onClick={UpdateUser}>Update</button>
                                            </div>
                                            <div className="msg-box"></div>
                                        </div>
                                        <Reset_Password />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}

export default Profile

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import Account_Sidebar from '../components/Account_Sidebar';
import ProductBox from '../components/ProductBox';

const Add_Products_In_Deals = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    if (JSON.parse(secureLocalStorage.getItem('login_data')).role !== 2) {
        window.location.replace('/login');
    }

    const [products, setProducts] = useState([]); // All products
    const [deals, setDeals] = useState([]); // Active deals
    const [selectedProducts, setSelectedProducts] = useState([]); // Currently selected products
    const [selectedDealId, setSelectedDealId] = useState(null); // Selected deal
    const [message, setMessage] = useState(''); // State for message box
    const [messageType, setMessageType] = useState(''); // Type of message: 'success' or 'error'
    const [showAllProducts, setShowAllProducts] = useState(false); // Toggle to show all products
    const user_id = JSON.parse(secureLocalStorage.getItem('login_data')).id;
    const [prevLength, setPrevLength] = useState(0);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const productsPerPage = 12; // Number of products per page

    const GetProducts = async () => {
        await axios.post(`https://beta.e-square.co.uk:3002/get-supplier-products`, { user_id: user_id }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } })
            .then((res) => {
                setProducts(res.data);
            });
    };

    const GetDeals = async () => {
        await axios.get('https://beta.e-square.co.uk:3002/active-deals', {
            headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
        })
            .then((res) => {
                setDeals(res.data);
                if (res.data.length > 0) {
                    setSelectedDealId(res.data[0].deal_id); // Select the first deal
                }
            });
    };

    const handleProductSelection = (product) => {
        if (selectedProducts.includes(product.id)) {
            setSelectedProducts(selectedProducts.filter(id => id !== product.id));
        } else {
            setSelectedProducts([...selectedProducts, product.id]);
        }
    };

    const handleProductRemoval = async (productId) => {
        if (!selectedDealId) {
            setMessage('Please select a deal to remove products from.');
            setMessageType('red');
            return;
        }

        try {
            const res = await axios.post(`https://beta.e-square.co.uk:3002/remove-product-from-deal/${selectedDealId}`, {
                product_id: productId
            }, {
                headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
            });

            if (res.status === 200) {
                setMessage('Product removed from the deal successfully!');
                setMessageType('green');
                setSelectedProducts(selectedProducts.filter(id => id !== productId));
            }
        } catch (error) {
            console.error('Error removing product from deal:', error);
            setMessage('Error removing product from deal. Please try again.');
            setMessageType('red');
        }
    };

    const addProductsToDeal = async () => {
        if (!selectedDealId) {
            setMessage('Please select a deal to add products to.');
            setMessageType('red');
            return;
        }

        try {
            const res = await axios.get(`https://beta.e-square.co.uk:3002/get-products-for-deal/${selectedDealId}/${user_id}`, {
                headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
            });
            const existingProductIds = res.data.map(product => product.id);

            const newProducts = selectedProducts.filter(productId => !existingProductIds.includes(productId));

            if (newProducts.length === 0) {
                setMessage('No new products to add.');
                setMessageType('info');
                return;
            }

            const response = await axios.post(`https://beta.e-square.co.uk:3002/add-products-to-deal/${selectedDealId}`, {
                product_ids: newProducts
            }, {
                headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
            });
            if (response.status === 201) {
                setMessage('Products added to the deal successfully!');
                setMessageType('green');
            }
        } catch (error) {
            console.error('Error adding products to deal:', error);
            setMessage('Error adding products to deal. Please try again.');
            setMessageType('red');
        }
    };

    const fetchProductsForDeal = async (dealId) => {
        try {
            const res = await axios.get(`https://beta.e-square.co.uk:3002/get-products-for-deal/${dealId}/${user_id}`, {
                headers: { 'Auth': secureLocalStorage.getItem('accessToken') }
            });
            const fetchedProducts = res.data.map(product => product.id);
            setPrevLength(fetchedProducts.length);
            setSelectedProducts(fetchedProducts);
        } catch (error) {
            // Handle error
        }
    };

    // Pagination logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        GetProducts();
        GetDeals();
    }, []);

    useEffect(() => {
        if (selectedDealId) {
            fetchProductsForDeal(selectedDealId);
        } else {
            setSelectedProducts([]);
        }
    }, [selectedDealId]);

    return (
        <section className="user-area">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-2 p-0"><Account_Sidebar /></div>
                    <div className="col-md-10 p-0">
                        <div className="body-wrapper">
                            <div className="userInfo">
                                <div className='switch-box-head'>
                                    <h3 className='mb-0'>Add Products to Deals</h3>
                                </div>
                                <div className='serach-boxxs'>
                                    <label htmlFor='deal-select' className='mt-0'>Select Deal</label>
                                    <select
                                        id='deal-select'
                                        className='form-control'
                                        value={selectedDealId || ''}
                                        onChange={(e) => setSelectedDealId(e.target.value)}
                                    >
                                        <option value='' hidden>Select a Deal</option>
                                        {deals.map(deal => (
                                            <option key={deal.deal_id} value={deal.deal_id}>{deal.deal_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='row'>
                                    {selectedDealId && selectedProducts.length > 0 && (
                                        selectedProducts.map(productId => {
                                            const product = products.find(item => item.id === productId);
                                            return product && (
                                                <div className='col-lg-2 col-md-3 mb-4 position-relative' key={product.id}>
                                                    <ProductBox {...product} />
                                                    <div className='action-btns'>
                                                        <button
                                                            onClick={() => handleProductRemoval(product.id)}
                                                            className='remove-tbnsd'
                                                        >
                                                            <i className="fa-sharp fa-solid fa-circle-xmark"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                    {selectedDealId && selectedProducts.length === 0 && (
                                        <div className='notfound'>No Products Found for this Deal</div>
                                    )}
                                    <div className='d-flex gap-3 mb-4 justify-content-end'>
                                        {selectedDealId && (
                                            <div className='mt-3 text-end'>
                                                <button
                                                    className='submit-btn'
                                                    onClick={() => setShowAllProducts(prev => !prev)}
                                                >
                                                    {showAllProducts ? 'Hide Products' : 'Add Products'}
                                                </button>
                                            </div>
                                        )}
                                        {selectedProducts.length > prevLength && selectedDealId && (
                                            <div className='mt-3 text-end'>
                                                <button onClick={addProductsToDeal} className='submit-btn'>
                                                    Add to Deal
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {message && <p className={`msg-box ${messageType}`}>{message}</p>}
                                </div>

                                {showAllProducts && (
                                    <>
                                        <div className='row'>
                                            {currentProducts.map((product) => (
                                                <div className='col-lg-2 col-md-3 mb-4 position-relative' key={product.id}>
                                                    <ProductBox {...product} />
                                                    <div className='action-btns'>
                                                        <button
                                                            onClick={() => handleProductSelection(product)}
                                                            className={selectedProducts.includes(product.id) ? 'select-tbnsd selected' : 'select-tbnsd'}
                                                        >
                                                            <i className={selectedProducts.includes(product.id) ? 'fa-solid fa-check' : 'fa-solid fa-plus'}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* Pagination */}
                                        <nav>
                                            <ul className='pagination'>
                                                {Array.from({ length: Math.ceil(products.length / productsPerPage) }, (_, index) => (
                                                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button onClick={() => paginate(index + 1)} className='page-link'>
                                                            {index + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Add_Products_In_Deals;

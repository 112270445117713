import React from 'react'
import Account_Sidebar from '../components/Account_Sidebar'
import { useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';
import { useEffect, useRef } from "react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';


const Order_Supplier = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    if(JSON.parse(secureLocalStorage.getItem('login_data')).role != 2){
        window.location.replace('/login');
    }
    $.DataTable = require('datatables.net')
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const navigate = useNavigate();
    function removeDuplicates(arr) {  
        let objTitle;  
        let newArray = [];
        let uniqueObject = {};     
        for (let i in arr) {     
            objTitle = arr[i]['id'];
            uniqueObject[objTitle] = arr[i];
        }
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }
        return newArray;
    }
    const tableRef = useRef()
    const tableName = "table1"

    const [orders, setOrders] = useState([]);
    useEffect(() => {
        GetOrders();
    }, [])

    useEffect(() => {
        setInterval(function () {
            $('[item_id]').unbind().click(function () {
                navigate(`/order-recieved/${$(this).attr('item_id')}`);
            });
        }, 100)
    })

    useEffect(() => {
        const table = $(`#${tableName}`).DataTable(
            {
                data: orders,
                columns: [
                    { title: "NO#" },
                    { title: "Order Number" },
                    { title: "Buyer Name" },
                    { title: "order status" },
                    { title: "Date" },
                    { title: "View" },
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
            }
        )
        // Extra step to do extra clean-up.
        return function () {
            table.destroy()
        }
    }, [orders])

    const GetOrders = async () => {
        setOrders(null)
        axios.get(`https://beta.e-square.co.uk:3002/get-orders-supplier/${JSON.parse(secureLocalStorage.getItem('login_data')).id}`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            let data = [];
            if (res.data.status !== 201){
                removeDuplicates(res.data).map((el, key) => {
                    data.push([
                        key + 1,
                        el.order_number,
                        el.billing_first_name + ' ' + el.billing_last_name,
                        el.order_status,
                        moment(el.created_at).format('DD-MM-YYYY'),
                        '<span><i item_id="' + el.id + '" class="fa-sharp fa-solid fa-eye"></i></span>'])
                })
            }
            setOrders(data);
        })
    }

    if (orders === null) {
        return <div className='Loading'></div>
    }
    return (
        <>
            <section className="user-area">
                <div className="container-fluid">
                    <div className="row justify-content-end">
                        <div className="col-md-2 p-0"><Account_Sidebar /></div>
                        <div className="col-md-10 p-0">
                            <div className="row m-0 p-4">
                                <table className="table table-bordered" width="100%" id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Order_Supplier
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useProductContext } from '../context/productcontext';
import axios from 'axios';

const ProductBox = ({ id, user_id, title, thumbnail, price, cut_price, first_name, last_name, profile_pic, type_category, sub_category }) => {
    const { getCatg, getParCatg } = useProductContext();
    const [rating_prd, setRating] = useState(null);
    const getRating = () => {
        axios.get(`https://beta.e-square.co.uk:3002/get-rating-product/${id}`).then((res) => {
            setRating(res.data)
        })
    }

    useEffect(() => {
        getRating();
    }, [])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    if (getParCatg === null || getCatg === null || rating_prd === null) {
        return <div className='product-item-mew prd-box-load'></div>
    }
    let tpname = '';
    let sbname = '';
    getParCatg.map((tp) => {
        if (type_category === tp.id) {
            tpname = tp.name;
        }
    })
    getCatg.map((tp) => {
        if (sub_category === tp.id) {
            sbname = tp.name;
        }
    })
    return (
        <>
            <div className="product-item-mew">
            <Link to={`/product-detail/${id}`}><img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/products/ath_${user_id}/${thumbnail}`} alt="Product" className="prod-img" />
                <h3>{title} </h3></Link>
                <h4><span className="dis-price">£{numberWithCommas(price)}</span><strike>£{numberWithCommas(cut_price)}</strike></h4>
                <div className="rate"><i className='fa fa-star'></i> {rating_prd.rating === null ? '0' : rating_prd.rating.toFixed(1)} Out of 5 <span>{rating_prd.count} reviews</span></div>
                <div className='category-dbag'>
                    <div><span><Link to={`/category/${tpname.toLowerCase()}/`}>{tpname}</Link></span> <span><Link to={`/category/${tpname.toLowerCase()}/${sbname.toLowerCase()}`}>{sbname}</Link></span></div>
                </div>
                {first_name !== undefined ? 
                <div className='supplir'>
                    <Link to={`/vendor-detail/${user_id}`}><span>
                        {profile_pic == null ? <img src="images/dummy-user.png" />: <img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/users/${profile_pic}`} />}
                        {first_name + ' ' + last_name}
                    </span></Link>
                </div> : false}
            </div>
        </>
    )
}


export default ProductBox

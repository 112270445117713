import React, { useEffect, useState } from 'react'
import axios from 'axios';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Terms = () => {
    const [cms, setCms] = useState(null);
    useEffect(() => {
        getCms();
    }, [])

    const getCms = async () => {
        await axios.get('https://beta.e-square.co.uk:3002/get-cms/terms').then(function (res) {
            setCms(res.data);
        })
    }
    if (cms === null) {
        return <div className='Loading'></div>
    }
    return (
        <section className='content-only-sec'>
            <div className='container'>
                <h2>{cms.terms.title}</h2>
                { renderHTML(cms.terms.description) }
            </div>
        </section>
    )
}

export default Terms
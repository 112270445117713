import React, { useEffect, useState } from 'react';
import Account_Sidebar from '../components/Account_Sidebar';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import ProductBox from '../components/ProductBox';
import { Link } from 'react-router-dom';

const My_Store = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }

    const [products, setProducts] = useState(null);
    const [stats, setStats] = useState(null);
    const [searchQ, SetSearchQ] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(12); // Set the number of products per page

    const user_id = JSON.parse(secureLocalStorage.getItem('login_data')).id;

    const GetProducts = async () => {
        await axios.post(`https://beta.e-square.co.uk:3002/get-supplier-products`, { user_id: user_id }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } })
            .then((res) => {
                setProducts(res.data);
        });
    };

    const GetStats = async () => {
        await axios.get(`https://beta.e-square.co.uk:3002/vendor-stats/${user_id}`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } })
            .then((res) => {
                setStats(res.data.data);
            });
    };

    useEffect(() => {
        GetProducts();
        GetStats();
    }, []);

    if (products === null || stats === null) {
        return <div className='Loading'></div>;
    }

    // Filter products based on the search query
    const filteredProducts = products.filter((item) => {
        if (searchQ === '') {
            return item;
        } else {
            return item.title.toLowerCase().includes(searchQ.toLowerCase());
        }
    });

    // Get current products based on the page and search results
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <section className="user-area">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-2 p-0"><Account_Sidebar /></div>
                    <div className="col-md-10 p-0">
                        <div className="body-wrapper">
                            <div className="userInfo">
                                <div className='switch-box-head'>
                                    <h3>My Store</h3>
                                </div>
                                <div className='row'>
                                    {/* Stats Section */}
                                    <div className='col-md-6'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#d3d8ff' }}>
                                            <i className="fa-solid fa-chart-line-up"></i>
                                            <div>
                                                <h4>{stats.products_sold_count}</h4>
                                                <span>Products Sold</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#dcffd3' }}>
                                            <i className="fa-solid fa-cubes"></i>
                                            <div>
                                                <h4>{stats.total_order_count}</h4>
                                                <span>Orders</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-4'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#fffcd3' }}>
                                            <i className="fa-solid fa-user"></i>
                                            <div>
                                                <h4>{stats.customers_count}</h4>
                                                <span>Customers</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mt-4'>
                                        <div className='box-dahsbrd' style={{ backgroundColor: '#ffd3d3' }}>
                                            <i className="fa-sharp fa-solid fa-dollar-sign"></i>
                                            <div>
                                                <h4>${stats.total_revenue.toFixed(2)}</h4>
                                                <span>Revenue</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='switch-box-head mt-5'>
                                    <h3>My Products</h3>
                                    <div className='serach-boxxs'>
                                        <input type='text' placeholder='Search Products' onChange={(e) => SetSearchQ(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        currentProducts.length === 0 ? <div className='notfound'>No Products Found</div>
                                            : currentProducts.map((item) => (
                                                <div key={item.id} className='col-lg-2 col-md-3 mb-4 position-relative'>
                                                    <ProductBox {...item} />
                                                    <div className='action-btnss'>
                                                        <Link to={`/edit-products/${item.id}`}>Edit</Link>
                                                        <button>Delete</button>
                                                    </div>
                                                </div>
                                            ))
                                    }
                                </div>
                                <nav>
                                    <ul className='pagination'>
                                        {Array.from({ length: Math.ceil(filteredProducts.length / productsPerPage) }, (_, index) => (
                                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                <button onClick={() => paginate(index + 1)} className='page-link'>
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default My_Store;

import React, { useEffect, useState } from 'react'
import { useProductContext } from '../context/productcontext';
import ProductBox from '../components/ProductBox';
import MultiRangeSlider from "../components/MultiRangeSlider";
import $ from 'jquery';
import { useParams } from 'react-router-dom';

const Search = () => {
    const { query } = useParams();
    const { products, getCatg, getParCatg } = useProductContext();
    const [price_fltr, SetPriceFltr] = useState({
        min: 0,
        max: 100000,
    })

    const [cat_fltr, SetCatFltr] = useState([])

    useEffect(() => {
        $('.frequently-list .panel-body ul li label').unbind().click(function () {
            let arr = [];
            $('[name="catogery"]:checked').each(function () {
                let val = $(this).val();
                arr.push(val);
            })
            SetCatFltr(arr);
        })

    })

    if (Object.keys(products).length === 0 || getCatg === null || getParCatg === null) {
        return <div className='Loading'></div>
    }
    return (
        <>
            <section className="productsec product-page-sec all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <div className="filter-sec">
                                <div className='price-range'>
                                    <h4>Price Range</h4>
                                    <MultiRangeSlider
                                        min={0}
                                        max={100000}
                                        onChange={({ min, max }) => {
                                            setTimeout(function () {
                                                SetPriceFltr({ min: min, max: max })
                                            }, 50)
                                        }}
                                    />
                                </div>
                                <div className="frequently-list wow fadeInLeft" style={{ visibility: 'visible', animationName: 'fadeInLeft' }}>
                                    <div className="panel-groupnew" id="accordion" role="tablist" aria-multiselectable="true">
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="headingOne">
                                                <h4 className="panel-title catogeriesbox">
                                                    <a role="button" className="hide-btn" aria-expanded="false"> Categories </a>
                                                </h4>
                                            </div>
                                            <div className="collapse show" id="headingone">
                                                <div className="panel-body categories-panelbody">
                                                    <ul>
                                                        {
                                                            getParCatg.map((p_cat) => {
                                                                return <>
                                                                    <li><h5>{p_cat.name}</h5></li>
                                                                    {
                                                                        getCatg.filter((d) => { return d.parent_id === p_cat.id }).toReversed().map((item) => {
                                                                            return <li>
                                                                                <label><input type="checkbox" name="catogery" value={item.id} className="categories-checkbox" />
                                                                                    {item.name} </label>
                                                                            </li>
                                                                        })
                                                                    }
                                                                </>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 prdt-pg-col">
                            <div className='search-keys'>
                                <h4>Search for : {query}</h4>
                            </div>
                            <div className="row wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                                {
                                    products.filter((elem) => {
                                        return elem.title.toLowerCase().includes(query.toLowerCase())
                                    }).filter((pd) => {
                                        return pd.price >= price_fltr.min && pd.price <= price_fltr.max
                                    }).filter((pd) => {
                                        return cat_fltr.length !== 0 ? cat_fltr.includes(pd.sub_category.toString()) : pd
                                    }).map((prd, key) => {
                                        if (key < 12) {
                                            return <div className="col-lg-3 col-md-4 mb-4"><ProductBox {...prd} /></div>
                                        }
                                    })
                                }
                                <div className='notfound'>No Products Found</div>
                            </div>
                        </div>
                    </div>
                    <div className="row wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                        {
                            products.filter((elem) => {
                                return elem.title.toLowerCase().includes(query.toLowerCase())
                            }).filter((pd) => {
                                return pd.price >= price_fltr.min && pd.price <= price_fltr.max
                            }).filter((pd) => {
                                return cat_fltr.length !== 0 ? cat_fltr.includes(pd.sub_category.toString()) : pd
                            }).map((prd, key) => {
                                if (key > 11) {
                                    return <div className="col-lg-3 col-md-6 mb-4"><ProductBox {...prd} /></div>
                                }
                            })
                        }
                    </div>
                </div>
            </section>



        </>
    )
}

export default Search


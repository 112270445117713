import React from 'react'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useProductContext } from '../context/productcontext';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Footer = () => {
    const { getParCatg } = useProductContext();
    const [CMS, setCMS] = useState(null);
    const [cms, setCms] = useState(null);

    useEffect(() => {
        GetCMS();
        getLogo();
        getCms();
    }, [])
    const GetCMS = async () => {
        await axios.get(`https://beta.e-square.co.uk:3002/site-info/`).then((res) => {
            setCMS(res.data);
        })
    }
    const [logo, setLogo] = useState(null);
    const getLogo = async () => {
        await axios.get('https://beta.e-square.co.uk:3002/getlogo2').then(res => {
            setLogo(res.data[0].img_path);
        })
    }

    const getCms = async () => {
        await axios.get('https://beta.e-square.co.uk:3002/get-cms/home').then(function (res) {
            setCms(res.data);
        })
    }

    const [newsletter, setNewsletter] = useState({
        email: '',
    })
    const setNewsletterParam = (e) => {
        setNewsletter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const SubscribeNewsletter = async (e) => {
        e.preventDefault();
        $('.SubscribeNewsletterForm input').each(function () {
            if ($(this).val() == '') {
                $(this).addClass('err')
                // return false;
            } else {
                $(this).removeClass('err')
            }
        })
        if (newsletter.newsletter_email != '' && newsletter?.newsletter_email?.indexOf('@') > 0) {
            await axios.post('https://beta.e-square.co.uk:3002/save-newsletter', newsletter).then(function (response) {
                if (response.data.status == '200') {
                    $('#newsletter-response').removeClass('text-danger').addClass('text-success').text(response.data.message);
                    setTimeout(function () {
                        $('#newsletter-response').text('')
                    }, 1500)
                    $('input[name=newsletter_email]').val('')
                } else {
                    $('#newsletter-response').removeClass('text-success').addClass('text-danger').text(response.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            $('#newsletter-response').removeClass('text-success').addClass('text-danger').text('A valid email address is required.');
        }
    }
    if (cms !== null && getParCatg !== null) {
        return (
            <footer className=" foot">
                <div className="container-fluid all-side-padding">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            <div className="foot_logo">
                                <Link to="/">
                                    {/* <img className="foot-logo" src="images/logo.png" /> */}
                                    <img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/${logo !== null ? logo : false}`} alt="logo" className="foot-logo" />
                                </Link>
                                {renderHTML(cms.Footer.description)}
                                <ul>
                                    <li><Link to><img src="images/app-store.png" /></Link></li>
                                    <li><Link to><img src="images/google-play.png" /></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-3 col-sm-3">
                            <div className="foot_comp">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/shop">Products</Link></li>
                                    <li><Link to="/testimonails">Testimonials</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-3 col-sm-3">
                            <div className="foot_comp">
                                <h4>Categories</h4>
                                <ul>
                                    {
                                        getParCatg.map((c_cat) => {
                                            return <li><Link to={`/category/${c_cat.name.toLowerCase()}`}>{c_cat.name}</Link></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            <div className="news_sub">
                                <h4>About</h4>
                                <ul>
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/faqs">Faqs</Link></li>
                                    <li><Link to="/privacy">Privacy & Policy</Link></li>
                                    <li><Link to="/terms">Terms & Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                            <div className="foot_comp">
                                <h4>Help &amp; Contact</h4>
                                <ul>
                                    <li><Link to="#">Seller Information Center</Link></li>
                                    <li><Link to="#">Contact us</Link></li>
                                </ul>
                                <h4>Community</h4>
                                <ul>
                                    <li><Link to="#">Announcements</Link></li>
                                    <li><Link to="#">Discussion boards</Link></li>
                                    <li><Link to="#">Giving Works</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-8 nav-flex brd-pad">
                        <div className="col-lg-6 col-md-12">
                            <div className="follow_Social">
                                <ul>
                                    <li><span>Follow Socials :</span></li>
                                    {CMS !== null ?
                                        CMS.map((item) => {
                                            if (item.type == 'facebook') {
                                                return <li><a href={item.value} target='_blank'><i className="fab fa-facebook" /></a></li>;
                                            }
                                        })
                                        : false}
                                    {CMS !== null ?
                                        CMS.map((item) => {
                                            if (item.type == 'twitter') {
                                                return <li><a href={item.value} target='_blank'><i className="fab fa-twitter" /></a></li>;
                                            }
                                        })
                                        : false}
                                    {CMS !== null ?
                                        CMS.map((item) => {
                                            if (item.type == 'instagram') {
                                                return <li><a href={item.value} target='_blank'><i className="fab fa-instagram" /></a></li>;
                                            }
                                        })
                                        : false}
                                    {CMS !== null ?
                                        CMS.map((item) => {
                                            if (item.type == 'linkedin') {
                                                return <li><a href={item.value} target='_blank'><i className="fab fa-linkedin" /></a></li>;
                                            }
                                        })
                                        : false}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="sign_up">
                                <div id="newsletter-response" className='text-end'></div>
                                <ul>
                                    <li><span>Sign Up For Newsletter</span></li>
                                    <li>
                                        <form action="javascritp:;" onSubmit={SubscribeNewsletter} className='SubscribeNewsletterForm' noValidate>
                                            <input type="email" name="newsletter_email" onChange={setNewsletterParam} placeholder="Enter email address" required />
                                            <button type="submit">Subscribe</button>
                                        </form>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer

import React, { useEffect, useState } from 'react'
import { useProductContext } from '../context/productcontext';
import ProductBox from '../components/ProductBox';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Vendor_Detail = () => {
    const formatter = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
    });
    const { products } = useProductContext();
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [rating_sup, setSupRating] = useState(null);

    const getVendor = () => {
        axios.get(`https://beta.e-square.co.uk:3002/single-vendor/${id}`).then((res) => {
            setData(res.data.data[0]);
        })
        axios.get(`https://beta.e-square.co.uk:3002/get-rating-supplier/${id}`).then((res) => {
            setSupRating(res.data)
        })
    }

    useEffect(() => {
        getVendor();
    }, [id])

    if (Object.keys(products).length === 0 || data === null || rating_sup === null) {
        return <div className='Loading'></div>
    }
    return (
        <>
            <div className='container-fluid mt-4 mb-4'>
                <div className="archive-header-3 mt-30 mb-80">
                    <div className="archive-header-3-inner d-flex gap-5">
                        <div className="vendor-logo mr-50">
                        {data.profile_pic == null ? <img src="images/dummy-user.png" />: <img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/users/${data.profile_pic}`} />}
                        </div>
                        <div className="vendor-content">
                            <div className="product-category">
                                <span className="text-muted">Since {formatter.format(Date.parse(data.created_at))}</span>
                            </div>
                            <h3 className="mb-2 text-white"><a className="text-white" href="javascript:;">{data.first_name + ' ' + data.last_name}</a></h3>
                            <div className="product-rate-cover mb-15">
                                <div className="product-rate d-inline-block">
                                    <div className="product-rating" style={{ width: (rating_sup.rating === null ? "0" : rating_sup.rating * 100) / 5 + '%'  }} />
                                </div>
                                <span className="font-small ml-5 text-muted"> ({rating_sup.rating === null ? "0" : rating_sup.rating.toFixed(1)})</span>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="vendor-des mb-15">
                                        <p className="font-sm text-white">{data.bio}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="vendor-info text-white mb-15">
                                        <ul className="font-sm">
                                            <li><i class="fa-light fa-location-dot"></i><strong>Address: </strong> <span>{data.billing_address_1}</span></li>
                                            <li><i className='fal fa-phone-alt'></i><strong>Call Us:</strong><span>{data.phone}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="follow-social">
                                        <h6 className="mb-15 text-white">Follow Us</h6>
                                        <ul className="social-network">
                                            <li className="hover-up">
                                                <a href="#">
                                                    <img src="images/social-tw.svg" alt />
                                                </a>
                                            </li>
                                            <li className="hover-up">
                                                <a href="#">
                                                    <img src="images/social-fb.svg" alt />
                                                </a>
                                            </li>
                                            <li className="hover-up">
                                                <a href="#">
                                                    <img src="images/social-insta.svg" alt />
                                                </a>
                                            </li>
                                            <li className="hover-up">
                                                <a href="#">
                                                    <img src="images/social-pin.svg" alt />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <h2>Test Supplier Products </h2>
                <div className="row wow fadeInRight" style={{ visibility: 'visible', animationName: 'fadeInRight' }}>
                    {
                        products.filter((item) => { return item.user_id == id }).map((prd, key) => {
                            return <div className="col-lg-3 col-md-6 mb-4"><ProductBox {...prd} /></div>
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Vendor_Detail
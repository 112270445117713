import React from 'react'
import Account_Sidebar from '../components/Account_Sidebar'
import { useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';
import { useEffect, useRef } from "react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';

const Returning_Costumers = () => {
    $.DataTable = require('datatables.net')
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    const navigate = useNavigate();
    const tableRef = useRef()
    const tableName = "table1"

    const [orders, setOrders] = useState(null);
    useEffect(() => {
        GetOrders();
    }, [])

    useEffect(() => {
        setInterval(function () {
            $('[item_id]').unbind().click(function () {
                navigate(`/order-detail/${$(this).attr('item_id')}`);
            });
        }, 100)
    })

    useEffect(() => {
        const table = $(`#${tableName}`).DataTable(
            {
                data: orders,
                columns: [
                    { title: "ID" },
                    { title: "Full Name" },
                    { title: "Email" },
                    { title: "Total Orders" },
                    { title: "Successful order" },
                    { title: "Last Order" },
                    { title: "Total Value" },
                ],
                destroy: true,
                searching: true,
            }
        )
        return function () {
            table.destroy()
        }
    }, [orders])

    const GetOrders = async () => {
        setOrders(null)
        axios.get(`https://beta.e-square.co.uk:3002/returning-customers/${JSON.parse(secureLocalStorage.getItem('login_data')).id}`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            let data = [];
            if (res.status === 200) {
                res.data.customers.map((el, key) => {
                    data.push([
                        el.user_id,
                        `<div class="user-fnaj">
                            <img src="https://beta.e-square.co.uk/admin/backend/public/uploads/users/${el.profile_pic ? el.profile_pic : "vendor-icon.png"}" />
                            <span>${el.full_name}</span>
                        </div>`,
                        el.email,
                        el.total_orders,
                        el.successful_orders,
                        moment(el.last_order_date).format('DD-MM-YYYY'),
                        "£" + numberWithCommas(el.total_value),
                    ])
                })
            }
            setOrders(data);
        })
    }
    console.log(orders)
    return (
        <>
            <section className="user-area">
                <div className="container-fluid">
                    <div className="row justify-content-end">
                        <div className="col-md-2 p-0"><Account_Sidebar /></div>
                        <div className="col-md-10 p-0">
                            <div className="row m-0 p-4">
                                <div className='switch-box-head'>
                                    <h3>Review Products</h3>
                                </div>
                                {orders === null ? <div className='Loading'></div> : <table className="table table-bordered" width="100%" id={tableName} ref={tableRef}></table>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Returning_Costumers

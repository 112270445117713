import axios from 'axios';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage'
import $ from 'jquery'

const ThankYou = () => {
    const {id} = useParams();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const PINT = urlParams.get('payment_intent');
    const PISC = urlParams.get('payment_intent_client_secret');
    const CheckPayment = async () => {
        await axios.post(`https://beta.e-square.co.uk:3002/check-payment/`, {PINT : PINT, PISC : PISC, ID : id}).then((res) => {
            if (res.data.status == '200') {
                $('.msg-box').removeClass('red')
                $('.msg-box').addClass('green').text(res.data.message);
                secureLocalStorage.removeItem("order")
                secureLocalStorage.removeItem("cart_data")
                secureLocalStorage.removeItem("discount")
            } else {
                $('.msg-box').removeClass('green')
                $('.msg-box').addClass('red').text(res.data.message);
            }
        })
    }
    const style = {
        'filter': 'hue-rotate(223deg) saturate(0.4)',
        'margin-bottom': '-50px',
        'position': 'relative',
        'z-index': '-1',
    }
    useEffect(() => {
        if(PINT !== null){
            CheckPayment();
        }
    }, [id])
    return (
        <div className='notfound d-block cart-not text-center'>
            <img src='https://cdn.shopify.com/s/files/1/0577/8181/3453/files/truck.gif?v=1643905486' style={style} />
            <h2>Your Order is <br /> Placed Successfully</h2>
        </div>
    )
}

export default ThankYou
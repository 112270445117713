import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Account_Sidebar from '../components/Account_Sidebar';
import { omit } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { useProductContext } from '../context/productcontext';
import secureLocalStorage from "react-secure-storage";

const Edit_Product = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { getCatg, getParCatg } = useProductContext();
    const [type_CAT, SetTYPE_CAT] = useState(null);
    const [filesx, setFiles] = useState([]);
    const [popupV, setPopV] = useState(0);
    const [variant, setVariant] = useState(null);
    const [variant_merge, setVariantMerge] = useState([]);

    let A_id = null;
    if (secureLocalStorage.getItem('login_data') !== null && JSON.parse(secureLocalStorage.getItem('login_data')).role === 2) {
        A_id = JSON.parse(secureLocalStorage.getItem('login_data')).id;
    } else {
        window.location.href = "/login";
    }
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState({});
    const [previe, SetPreview] = useState(null);


    const GetPRD = async () => {
        await axios.get(`https://beta.e-square.co.uk:3002/single-product-edit/${id}`).then((res) => {
            setData(res.data.data[0])
            setVariantMerge(res.data.variation);
            SetTYPE_CAT(res.data.data[0].type_category)
        })
    }
    useEffect(() => {
        GetPRD();
    }, [id])


    const onInputChanged = (e) => {
        let arr = [];
        const files = e.currentTarget.files;
        Array.from(files).forEach((file, key) => {
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    $('.images-flcss>#preview_' + key).attr("src", reader.result)
                },
                false
            );

            if (file) {
                reader.readAsDataURL(file);
            }

            arr.push(file)
        });
        setFiles(arr);
        validate(e.target.name, files[0].name);
    }


    let err = {};
    const validate2 = (name, value) => {
        if (name === 'title')
            if (value === null || value == undefined || value == '') {
                err = { ...err, title: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "title");
                setErrors(newObj);
            }
        if (name === 'brand')
            if (value === null || value == undefined || value == '') {
                err = { ...err, brand: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "brand");
                setErrors(newObj);
            }
        if (name === 'short_description')
            if (value === null || value == undefined || value == '') {
                err = { ...err, short_description: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "short_description");
                setErrors(newObj);
            }
        if (name === 'description')
            if (value === null || value == undefined || value == '') {
                err = { ...err, description: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "description");
                setErrors(newObj);
            }
        if (name === 'price')
            if (value === null || value == undefined || value == '') {
                err = { ...err, price: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "price");
                setErrors(newObj);
            }
        if (name === 'cut_price')
            if (value === null || value == undefined || value == '') {
                err = { ...err, cut_price: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "cut_price");
                setErrors(newObj);
            }
        if (name === 'quantity')
            if (value === null || value == undefined || value == '') {
                err = { ...err, quantity: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "quantity");
                setErrors(newObj);
            }
        if (name === 'type_category')
            if (value === null || value == undefined || value == '') {
                err = { ...err, type_category: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "type_category");
                setErrors(newObj);
            }
        if (name === 'sub_category')
            if (value === null || value == undefined || value == '') {
                err = { ...err, sub_category: 'Field cannot be empty' };
            } else {
                let newObj = omit(errors, "sub_category");
                setErrors(newObj);
            }
        if (name === 'thumbnail')
            if (value === null || value == undefined || value == '') {
                err = { ...err, thumbnail: 'Thumbnail is Required' };
            } else {
                let newObj = omit(errors, "thumbnail");
                setErrors(newObj);
            }
        if (name === 'girth') {
            if (value === null || value == undefined || value == '' || value >= 226) {
                err = { ...err, girth: 'Girth should be less than or equal to 225' };
            } else {
                let newObj = omit(errors, "girth");
                setErrors(newObj);
            }
        }
        if (name === 'length') {
            if (value === null || value == undefined || value == '' || value >= 121) {
                err = { ...err, length: 'Length should be less than or equal to 120' };
            } else {
                let newObj = omit(errors, "length");
                setErrors(newObj);
            }
        }
        if (name === 'weight') {
            if (value === null || value == undefined || value == '' || value >= 16) {
                err = { ...err, weight: 'Weight should be less than and equal to 15' };
            } else {
                let newObj = omit(errors, "weight");
                setErrors(newObj);
            }
        }
        setErrors(err);
    };    
    const validate = (name, value) => {
        switch (name) {
            case 'title':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, title: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "title");
                    setErrors(newObj);
                }
                break;
            case 'brand':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, brand: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "brand");
                    setErrors(newObj);
                }
                break;
            case 'short_description':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, short_description: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "short_description");
                    setErrors(newObj);
                }
                break;
            case 'description':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, description: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "description");
                    setErrors(newObj);
                }
                break;
            case 'price':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, price: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "price");
                    setErrors(newObj);
                }
                break;
            case 'cut_price':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, cut_price: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "cut_price");
                    setErrors(newObj);
                }
                break;
            case 'quantity':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, quantity: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "quantity");
                    setErrors(newObj);
                }
                break;
            case 'type_category':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, type_category: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "type_category");
                    setErrors(newObj);
                }
                break;
            case 'sub_category':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, sub_category: 'Field cannot be empty' });
                } else {
                    let newObj = omit(errors, "sub_category");
                    setErrors(newObj);
                }
                break;
            case 'thumbnail':
                if (value === null || value == undefined || value == '') {
                    setErrors({ ...errors, thumbnail: 'Thumbnail is Required' });
                } else {
                    let newObj = omit(errors, "thumbnail");
                    setErrors(newObj);
                }
                break;
            case 'girth':
                if (value === null || value == undefined || value == '' || value >= 226) {
                    setErrors({ ...errors, girth: 'Girth should be less than or equal to 225' });
                } else {
                    let newObj = omit(errors, "girth");
                    setErrors(newObj);
                }
                break;
            case 'length':
                if (value === null || value == undefined || value == '' || value >= 121) {
                    setErrors({ ...errors, length: 'Length should be less than or equal to 120' });
                } else {
                    let newObj = omit(errors, "length");
                    setErrors(newObj);
                }
                break;
            case 'weight':
                if (value === null || value == undefined || value == '' || value >= 16) {
                    setErrors({ ...errors, weight: 'Weight should be less than and equal to 15' });
                } else {
                    let newObj = omit(errors, "weight");
                    setErrors(newObj);
                }
                break;
            default:
                break;
        }
    };

    const setVal = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        validate(e.target.name, e.target.value);
        if (e.target.name === 'type_category') {
            SetTYPE_CAT(e.target.value);
        }
    }
    const handleEditorChange = (e) => {
        setData((prev) => ({ ...prev, [e.target.id]: e.target.getContent() }));
        validate(e.target.id, e.target.getContent());
    }
    const previewFile = () => {
        const preview = document.querySelector(".prd-image-upld img");
        const label = $(".upload-btn-wrapper label");
        const file = document.querySelector("#profile").files[0];
        const reader = new FileReader();

        reader.addEventListener(
            "load",
            () => {
                SetPreview(reader.result);
                label.text(file.name)
            },
            false
        );

        if (file) {
            reader.readAsDataURL(file);
        }
    }
    const handleFile = (e) => {
        setData((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));
        validate(e.target.name, e.target.files[0].file);
        previewFile();
    }
    const Upload_Product = (e) => {
        e.preventDefault();
        e.target.classList.add('loadin');
        Object.keys(data).map((ket) => {
            validate2(ket, data[ket]);
        });
        console.log(data, variant_merge);
        if (Object.keys(err).length === 0) {
            axios.put(`https://beta.e-square.co.uk:3002/update-product/${A_id}`, data, { headers: { "Content-Type": "multipart/form-data", 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
                if (res.data.status == '200') {
                    $('.msg-box').removeClass('red')
                    if (filesx.length > 0) {
                        const formData = new FormData();
                        for (let i = 0; i < filesx.length; i++) {
                            formData.append("images", filesx[i]);
                        }
                        axios.post(`https://beta.e-square.co.uk:3002/update-product-images/${A_id}/${id}`, formData, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } });
                    }
                    if (variant_merge !== null && variant_merge.length > 0) {
                        axios.post(`https://beta.e-square.co.uk:3002/add-variants/${A_id}/${id}`, { variant_merge });
                    }
                    $('.msg-box').addClass('green').text(res.data.message);
                    setTimeout(function () {
                        navigate('/my-store')
                    }, 1000)
                } else {
                    e.target.classList.remove('loadin');
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(res.data.message);
                }
            })
        } else {
            e.target.classList.remove('loadin');
        }
    }

    const Variant_SEP = () => {
        let text = $('.vairants-boxxs>input').val();
        if (variant === null) {
            setVariant(text);
        } else {
            setVariant(variant + ',' + text);
        }
        $('.vairants-boxxs>input').val('');
    }

    const AddVart = () => {
        if (variant !== null && $('[name="variant_name"]').val() !== '') {
            if (variant_merge === null) {
                setVariantMerge([{
                    name: $('[name="variant_name"]').val(),
                    variants: variant,
                }]);
            } else {
                setVariantMerge([...variant_merge, {
                    name: $('[name="variant_name"]').val(),
                    variants: variant,
                }]);
            }
            setVariant(null);
            $('[name="variant_name"]').val('');
            setPopV(0)
        }
    }
    const removeVart = (key) => {
        const array = variant_merge;
        axios.post(`https://beta.e-square.co.uk:3002/delete-variant/`, { id: array[key].id, prd_id: id }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            if (res.data.status === 200) {
                setVariantMerge(res.data.data);
            }
        })
    }

    if (getParCatg === null || getCatg === null || data === null) {
        return <div className='Loading'></div>
    }
    return (
        <section className="user-area">
            <div className="container-fluid">
                <div className="row justify-content-end">
                    <div className="col-md-2 p-0"><Account_Sidebar /></div>
                    <div className="col-md-10 p-0">
                        <div className="body-wrapper">
                            <div className="userInfo">
                                <div className='switch-box-head'>
                                    <h3>Upload New Product</h3>
                                </div>
                                <div className="row upload-prd">
                                    <div className="col-lg-12">
                                        <div className='row'>
                                            <div className='col-lg-3'>
                                                <div className='prd-image-upld'>
                                                    {errors.thumbnail ? <div className='errorr mb-0'>{errors.thumbnail}</div> : false}
                                                    <span>{previe !== null ?
                                                        <img src={previe} />
                                                        : (data.thumbnail !== "" ? <img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/products/ath_${data.user_id}/${data.thumbnail}`} /> : <i className="fa-duotone fa-image"></i>)}</span>
                                                    <input type='file' name="thumbnail" onChange={handleFile} id='profile' />
                                                </div>
                                            </div>
                                            <div className='col-lg-9'>
                                                <div className='row'>
                                                    <div className="col-lg-12">
                                                        <label>Title</label>
                                                        <input type="text" name='title' onChange={setVal} defaultValue={data.title} />
                                                        {errors.title ? <div className='errorr mb-0'>{errors.title}</div> : false}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Brand Name</label>
                                                        <input type="text" name='brand' onChange={setVal} defaultValue={data.brand} />
                                                        {errors.brand ? <div className='errorr mb-0'>{errors.brand}</div> : false}
                                                    </div>
                                                    <div className="col">
                                                        <label>Select Type</label>
                                                        <select name='type_category' onChange={setVal}>
                                                            <option hidden>Select Type</option>
                                                            {
                                                                getParCatg.map((p_cat) => {
                                                                    if (data.type_category == p_cat.id) {
                                                                        return <option selected value={p_cat.id}>{p_cat.name}</option>
                                                                    } else {
                                                                        return <option value={p_cat.id}>{p_cat.name}</option>
                                                                    }
                                                                })
                                                            }
                                                        </select>
                                                        {errors.type_category ? <div className='errorr mb-0'>{errors.type_category}</div> : false}
                                                    </div>
                                                    {
                                                        type_CAT !== null ? <div className="col">
                                                            <label>Select Category</label>
                                                            <select name='sub_category' onChange={setVal}>
                                                                <option selected hidden>Select Category</option>
                                                                {
                                                                    getCatg.filter((d) => { return d.parent_id == type_CAT }).map((item) => {
                                                                        if (data.sub_category == item.id) {
                                                                            return <option selected value={item.id}>{item.name}</option>
                                                                        } else {
                                                                            return <option value={item.id}>{item.name}</option>
                                                                        }
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.sub_category ? <div className='errorr mb-0'>{errors.sub_category}</div> : false}
                                                        </div> : false
                                                    }
                                                    <div className="col-12"></div>
                                                    <div className="col-lg-4">
                                                        <label>Price</label>
                                                        <input type="number" name='price' onChange={setVal} defaultValue={data.price} />
                                                        {errors.price ? <div className='errorr mb-0'>{errors.price}</div> : false}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Previous Price</label>
                                                        <input type="number" name='cut_price' onChange={setVal} defaultValue={data.cut_price} />
                                                        {errors.cut_price ? <div className='errorr mb-0'>{errors.cut_price}</div> : false}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Quantity</label>
                                                        <input type="number" name='quantity' onChange={setVal} defaultValue={data.quantity} />
                                                        {errors.quantity ? <div className='errorr mb-0'>{errors.quantity}</div> : false}
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <label>Grith</label>
                                                        <input type="number" name='girth' onChange={setVal} defaultValue={data.girth} />
                                                        {errors.girth ? <div className='errorr mb-0'>{errors.girth}</div> : false}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Length</label>
                                                        <input type="number" name='length' onChange={setVal} defaultValue={data.length} />
                                                        {errors.length ? <div className='errorr mb-0'>{errors.length}</div> : false}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Weight</label>
                                                        <input type="number" name='weight' onChange={setVal} defaultValue={data.weight} />
                                                        {errors.weight ? <div className='errorr mb-0'>{errors.weight}</div> : false}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='add_variation'>
                                        {
                                            variant_merge !== null && variant_merge.length !== 0 ?
                                                variant_merge.map((item, key) => {
                                                    return <div className='variants-visi'>
                                                        <span>{item.name}</span>
                                                        <div className='badges-variant'>
                                                            {
                                                                item.variants.split(',').map((ver) => {
                                                                    return <div><span>{ver}</span></div>
                                                                })
                                                            }
                                                        </div>
                                                        <del onClick={() => { removeVart(key) }}>X</del>
                                                    </div>
                                                })
                                                : false
                                        }
                                        <button className='btn-vaiatn' onClick={() => { setPopV(1) }}>Add Varaitons <i class="fa-sharp fa-solid fa-plus mx-3"></i></button>
                                    </div>
                                    <div className='col-md-12 mt-4'>
                                        <div className='addmore-images'>
                                            <input type='file' name='images' multiple onChange={onInputChanged} />
                                            {
                                                data.images === null || data.images === '' ? filesx.length == 0 ?
                                                    <div>
                                                        <i className="fa-duotone fa-images"></i>
                                                        More Images
                                                    </div>
                                                    : <div className='images-flcss'>
                                                        {filesx.map((img, key) => {
                                                            return <img id={`preview_${key}`} src="a" />
                                                        })}
                                                    </div> : filesx.length == 0 && data.images !== null ?
                                                    <div className='images-flcss'>
                                                        {
                                                            data.images.split(',').map((imags) => {
                                                                return <img src={`https://beta.e-square.co.uk/admin/backend/public/uploads/products/ath_${data.user_id}/${imags}`} />
                                                            })
                                                        }
                                                    </div>
                                                    : <div className='images-flcss'>
                                                        {filesx.map((img, key) => {
                                                            return <img id={`preview_${key}`} src="a" />
                                                        })}
                                                    </div>
                                            }
                                        </div>
                                        {errors.images ? <div className='errorr'>{errors.images}</div> : false}
                                    </div>
                                    <div className="col-lg-5">
                                        <label>Short Description</label>
                                        <textarea name='short_description' onChange={setVal} defaultValue={data.short_description}></textarea>
                                        {errors.short_description ? <div className='errorr mb-0'>{errors.short_description}</div> : false}
                                        <div className="col-lg-6 mt-4">
                                            <button className="login_btn" onClick={Upload_Product}>Update</button>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <label className='mb-1'>Description</label>
                                        {/* <textarea name='description' onChange={setVal}></textarea> */}
                                        <Editor
                                            apiKey='jjoezv7zjhdqr8rlsyfxlcrfp36kxt1fx0aaeboyoucksasy'
                                            id="description"
                                            initialValue={data.description}
                                            init={{
                                                plugins: "link image code",
                                                toolbar: "undo redo | bold italic | alignleft aligncenter alignright | code",
                                            }}
                                            onChange={handleEditorChange}
                                        />
                                        {errors.description ? <div className='errorr mb-0'>{errors.description}</div> : false}
                                    </div>
                                    <div className="msg-box"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                popupV !== 0 ? <div className='variant-madal'>
                    <h3>Add New Variation <i class="fa-solid fa-circle-xmark" onClick={() => { setPopV(0) }}></i></h3>
                    <div className='userInfo'>
                        <label>Name</label>
                        <input type='text' name='variant_name' />
                        <div className='vairants-boxxs'>
                            <input type='text' placeholder='variant' />
                            <button onClick={Variant_SEP}><i class="fa-solid fa-plus"></i></button>
                        </div>
                        <div className='badges-variant mt-3 mb-3'>
                            {variant !== null && variant !== '' ?
                                variant.split(',').map((ver, key) => {
                                    return <div><span>{ver}</span></div>
                                })
                                : false}
                        </div>
                        <button className='btn-vaiatn' onClick={AddVart}>Add Variation</button>
                    </div>
                </div> : false
            }
        </section>
    )
}

export default Edit_Product
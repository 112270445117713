import React from 'react'
import axios from 'axios';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import  secureLocalStorage  from  "react-secure-storage";

const OTP_varify = () => {

    const navigate = useNavigate();


    useEffect(() => {
        if (secureLocalStorage.getItem('forgot_email') == null) {
            navigate('/login')
        }
        setTimeout(function () {
            secureLocalStorage.removeItem('forgot_email');
        }, 60000)
        $(".verification-code--inputs input").keyup(function (event) {
            if ($(this).val() > -1) {
                if (event.key == 0 || event.key == 1 || event.key == 2 || event.key == 3 || event.key == 4 || event.key == 5 || event.key == 6 || event.key == 7 || event.key == 8 || event.key == 9) {
                    $(this).next().get(0).focus();
                }
            } else {
                var $th = $(this);
		        $th.val( $th.val().replace(/[^0-9]/g, function(str) { return ''; } ) );
                if (event.key == 'Backspace') {
                    $(this).prev().get(0).focus();
                }
            }
        });
    }, [])

    const Varify = async (e) => {
        e.preventDefault();
        let opt = "";
        $(".verification-code--inputs input").each(function () {
            opt = opt + $(this).val();
        })
        if (opt != '') {
            const values = {
                otp: opt,
                email: JSON.parse(secureLocalStorage.getItem('forgot_email')).email
            }
            await axios.post('https://beta.e-square.co.uk:3002/varify-otp', values).then(function (response) {
                if (response.data.status == '200') {
                    $('.msg-box').removeClass('red')
                    $('.msg-box').addClass('green').text(response.data.message);
                    $('.aut-form-box input').val('');
                    localStorage.setItem('login_data', JSON.stringify(response.data.data[0]));
                    localStorage.removeItem('forgot_email');
                    setTimeout(function () {
                        navigate('/update-password')
                    }, 1000)
                } else {
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(response.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    const ResendOtp = async (e) => {
        e.preventDefault();
        await axios.post('https://beta.e-square.co.uk:3002/forgot-pass', {email : JSON.parse(localStorage.getItem('forgot_email')).email}).then(function (response) {
            if (response.data.status == '200') {
                $('.msg-box').removeClass('red')
                $('.msg-box').addClass('green').text(response.data.message);
                setTimeout(function () {
                    navigate('/otp-verify')
                }, 1000)
            } else {
                $('.msg-box').removeClass('green')
                $('.msg-box').addClass('red').text(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <section className="aouther-form-page">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 centerCol">
                        <div className="auth-head">
                            <h2>Email Verification</h2>
                            <div className="aut-form-box">
                                <form action="#">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="verification-code--inputs">
                                                <input type="text" maxLength={1} />
                                                <input type="text" maxLength={1} />
                                                <input type="text" maxLength={1} />
                                                <input type="text" maxLength={1} />
                                                <input type="text" maxLength={1} />
                                                <input type="text" maxLength={1} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-center">
                                            <div className="auth-btn">
                                                <button className='w-100 mt-4 login_btn' onClick={Varify}> Verify OTP</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 d-flex align-items-center justify-content-end mt-4">
                                            <a href="#" onClick={ResendOtp}>Resend OTP</a>
                                        </div>
                                        <div className='msg-box'></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OTP_varify